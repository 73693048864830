import React from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { useStatus } from '../providers/Status';

const GET_STRING = gql`
  query GetContent($id: String!) {
    content(id: $id) {
      id
      person {
        id
      }
      movie {
        id
      }
      entries {
        id
        lang
        text
        voice
      }
    }
  }
`;


const entryColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 2 },
  { field: 'lang', headerName: 'Language', flex: 0.5 },
  { field: 'text', headerName: 'Text', flex: 2 },
  { field: 'voice', headerName: 'Voice', flex: 2 },
];

export const ContentEditor: React.FC = () => {
  const { id } = useParams<'id'>();
  const navigate = useNavigate();

  const { setStatus, showMessage } = useStatus();
  const [content, setContent] = React.useState<any>(undefined);
  const [getString, { loading, error }] = useLazyQuery(GET_STRING, {
    variables: { id },
    onError(error) {
      showMessage({
        message: error.message,
        severity: 'error',
      });
    },
    onCompleted(data) {
      setContent(data.content);
    }
  });

  React.useEffect(() => {
    if (!id || !getString) return;

    getString({
      variables: { id },
    });
  }, [id, getString]);

  const onRowClick: GridEventListener<"rowClick"> = React.useCallback((params: GridRowParams, details) => {
    // Get the selected row's ID and language
    console.info(JSON.stringify({ row: params.row }, null, 2));

    navigate(`/entry/${params.row.lang}/${params.row.id}`);
  }, [navigate]);

  React.useEffect(() => {
    setStatus({
      isBusy: loading,
    });
  }, [loading, setStatus]);


  if (!content || loading || error) return <></>;

  return <Container sx={{ mt: 2 }}><Stack direction="column" sx={{ flex: 3 }} spacing={2}>
    <Typography variant='h4'>{content.id}</Typography>
    <DataGrid
      rows={content.entries}
      columns={entryColumns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 25 },
        },
      }}
      pageSizeOptions={[25, 50, 100]}
      onRowClick={onRowClick}
      checkboxSelection
    />
  </Stack></Container>;
}
import React from 'react';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { useAuth } from './Auth';

const { REACT_APP_API_URL } = process.env;

export const ApolloAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { token } = useAuth();

  const client = new ApolloClient({
    uri: `${REACT_APP_API_URL}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
import { Alert, AlertColor, Snackbar } from '@mui/material';
import React from 'react';

interface CurrentStatus {
  progress?: number;
  isBusy: boolean;
}

interface ErrorProps {
  message: string;
  severity: AlertColor;
}

interface StatusContextProps {
  status: CurrentStatus;
  setStatus: (value: Partial<CurrentStatus>) => void;
  showMessage: (message: ErrorProps) => void;
}

const EMPTY_STATUS: CurrentStatus = {
  isBusy: false,
};

const statusContext = React.createContext<StatusContextProps>({
  status: EMPTY_STATUS,
  setStatus: () => { },
  showMessage: () => { },
});

export const useStatus = () => React.useContext(statusContext);

export const WithStatus: React.FC<{ children: React.ReactNode; }> = ({ children }) => {
  const [status, setStatusValue] = React.useState<CurrentStatus>(EMPTY_STATUS);
  const [message, showMessage] = React.useState<ErrorProps | null>(null);

  const setStatus = (value: Partial<CurrentStatus>) => setStatusValue((current) => ({
    ...current,
    ...value,
  }));

  const closeError = () => showMessage(null);

  return <>
    <statusContext.Provider children={children} value={{
      status,
      setStatus,
      showMessage,
    }} />
    <Snackbar open={Boolean(message)} autoHideDuration={6000} onClose={closeError}>
      <Alert onClose={closeError} severity={message?.severity ?? 'error'} sx={{ width: '100%' }}>{message?.message}</Alert>
    </Snackbar>
  </>;
};

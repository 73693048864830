import React from 'react';
import './App.css';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/MenuRounded';


import {
  BrowserRouter as Router, Route, Routes,
} from 'react-router-dom';


import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth } from './providers/Auth';
import { ContentList } from './pages/ContentList';
import { useStatus } from './providers/Status';
import { ContentEditor } from './pages/ContentEditor';
import { EntryEditor } from './pages/EntryEditor';
import { Container } from '@mui/material';


function App() {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const { onLogout } = useAuth();
  const { status } = useStatus();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Router>
        <CssBaseline />
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setDrawerOpen(!isDrawerOpen)}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'left' }}>Movie Quiz CMS</Typography>
            <Button onClick={onLogout} sx={{ color: 'white' }}>Log Out</Button>
          </Toolbar>
        </AppBar>


        {status.isBusy && <LinearProgress />}

        <Container sx={{ flexGrow: 1, mt: 2 }}>
          <Routes>
            <Route path="content">
              <Route path="/content" element={<ContentList />} />
              <Route path=":id" element={<ContentEditor />} />
            </Route>

            <Route path="/entry/:lang/:id" element={<EntryEditor />} />
          </Routes>
        </Container>

      </Router>
    </Box >
  );
}

export default App;

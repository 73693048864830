import React from 'react';

import { useParams } from 'react-router-dom';
import { useStatus } from '../providers/Status';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useLazyQuery, gql } from '@apollo/client';

const GET_ENTRY = gql`
  query GetEntry($id: String!, $lang: String!) {
    entry(id: $id, lang: $lang) {
      id
      lang
      text
      voice
    }
  }
`;

export const EntryEditor: React.FC = () => {
  const { id, lang } = useParams();
  const { setStatus, showMessage } = useStatus();
  const [entry, setEntry] = React.useState<any>(undefined);
  const [getEntry, { loading, error }] = useLazyQuery(GET_ENTRY, {
    variables: { id, lang },
    onError(error) {
      showMessage({
        message: error.message,
        severity: 'error',
      });
    },
    onCompleted(data) {
      setEntry(data.entry);
    }
  });

  React.useEffect(() => {
    if (!id || !getEntry || !lang) return;

    getEntry({
      variables: { id, lang },
    });
  }, [id, getEntry, lang]);

  React.useEffect(() => {
    setStatus({ isBusy: loading });
  }, [loading, setStatus]);

  React.useEffect(() => {
    if (error) {
      showMessage({
        message: error.message,
        severity: 'error',
      });
    }
  }, [error, showMessage]);

  if (!entry) return null;

  return (
    <Container>
      <Stack spacing={2}>
        <Typography variant="h4">
          {entry.id}
        </Typography>
        <Typography variant="body1">
          {entry.lang}
        </Typography>
        <Typography variant="body1">
          {entry.text}
        </Typography>
        <Typography variant="body1">
          {entry.voice}
        </Typography>
      </Stack>
    </Container>
  );

};
import * as React from 'react';

const { REACT_APP_AUTH_ENDPOINT } = process.env;

interface AuthContextData {
  token?: string;
  onLogout?: () => void;
}

const AuthContext = React.createContext<AuthContextData>({});

export const useAuth = () => React.useContext(AuthContext);


export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [token, setToken] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      setToken(token);
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      token = queryParams.get('token');

      if (token) {
        localStorage.setItem('token', token);
        setToken(token);
        window.history.replaceState({}, '', window.location.pathname);
      } else {
        window.location.replace(REACT_APP_AUTH_ENDPOINT!);
      }
    }
  }, []);

  const onLogout = React.useCallback(() => {
    localStorage.removeItem('token');
    setToken(undefined);
    window.location.replace(REACT_APP_AUTH_ENDPOINT!);
  }, []);



  return <AuthContext.Provider value={{ token, onLogout }}>{token && children}</AuthContext.Provider>;
};
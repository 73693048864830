import { gql, useQuery, useMutation } from '@apollo/client';
import Container from '@mui/material/Container';
import { DataGrid, GridColDef, useGridApiContext, GridToolbarContainer } from '@mui/x-data-grid';
import * as React from 'react';
import { useStatus } from '../providers/Status';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';


const GET_STRINGS = gql`query {
  contents {
		contents {
			id
		}
	}
}`;

const DELETE_STRING = gql`mutation DeleteContent($id: String!) {
  deleteContent(id: $id) {
    id
  }
}`;

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 480 },
];

export const ContentList: React.FC = () => {
  const [rows, setRows] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const { loading, error } = useQuery(GET_STRINGS, {
    onCompleted(data) {
      setRows(data.contents.contents);
    }
  });
  const { setStatus, showMessage } = useStatus();

  React.useEffect(() => {
    if (error) {
      showMessage({
        message: error.message,
        severity: 'error',
      });
    }
  }, [error, showMessage]);

  React.useEffect(() => {
    setStatus({
      isBusy: loading,
    });
  }, [loading, setStatus]);

  const onRowClick = React.useCallback((params: any) => {
    console.log(params);
    navigate(`/content/${params.id}`);
  }, [navigate]);

  return (

    <Container sx={{ mt: 2 }}>

      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        slots={{
          toolbar: AnswerGridToolbar,
        }}
        onRowClick={onRowClick}
        pageSizeOptions={[25, 50, 100]}
        checkboxSelection
      />
    </Container>
  );
}

const AnswerGridToolbar = () => {
  const apiRef = useGridApiContext();
  const [deleteContent] = useMutation(DELETE_STRING);
  const { showMessage } = useStatus();

  const handleDelete = React.useCallback(async () => {
    const selectedIds = Array.from(apiRef.current.getSelectedRows().keys());

    await Promise.all(selectedIds.map(async (id) => {
      await deleteContent({ variables: { id } }).catch((error) => {
        showMessage({
          message: error.message,
          severity: 'error',
        });
      });
    }));
  }, [apiRef, deleteContent, showMessage]);

  return (
    <GridToolbarContainer>
      <IconButton onClick={handleDelete} ><DeleteIcon /> </IconButton>
    </GridToolbarContainer>
  );
}
